 
 const  routes = [
      
      { path: '/', element: require('./routed/site/index').default },
      { path: '/about', element: require('./routed/site/about').default },
      { path: '/docs', element: require('./routed/site/docs').default },
      { path: '/products', element: require('./routed/site/products').default },
      { path: '/news', element: require('./routed/site/news').default },
      { path: '/contact', element: require('./routed/site/contact').default },
      { path: '/login', element: require('./routed/site/login').default },
      { path: '/ping', element: require('./routed/api/ping').default },

      // { path: '/demo/templates/corporate/docs/tc', element: require('./site/nudocs/tc').default },
      // { path: '/demo/templates/corporate/docs/cits', element: require('./site/nudocs/cits').default },
      // { path: '/demo/templates/corporate/docs/cms', element: require('./site/nudocs/cms').default },
      // { path: '/demo/templates/corporate/docs/tgecp', element: require('./site/nudocs/tgecp').default },


  

      ]; 
 


export default routes;