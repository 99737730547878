  
 import React from 'react';
 import Layout from '../../components/Layout';
import DocViewer from '../../components/DocViewer' 
 const App = () => { 
   return (     <Layout>   <DocViewer/>    </Layout>    );
 }
  
 
   export default App;
  
  
 