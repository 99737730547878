import React, {  useEffect, forwardRef, useState } from 'react';

import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';   
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';
      
import RelatedDocs from './../../components/RelatedDocs';
import useFile from '@iorp/react-aid/lib/hooks/useFile'; 
 
import './style.css' 

function CustomFileReader({src}) {
 const { fileContent, fileUrl, isLoading, error } = useFile(src);
 const [fileInfo,setFileInfo]= useState(null);
 useEffect(() => {
 
    if(fileContent){
      let _fileInfo={};
      _fileInfo.title = fileContent.trim().split('\n')[0].trim();
      _fileInfo.text = fileContent.trim().split(_fileInfo.title)[1].trim();
      setFileInfo(_fileInfo);
    }
 
 }, [fileContent]);

 if (isLoading) {
   return <div>Loading...</div>;
 }

 if (error) {
   return <div>Error: {error.message}</div>;
 }

 return (
  <>
{fileInfo &&  <div className="col-md-8">
  <h3 className="pb-4 mb-4 fst-italic border-bottom">
    {fileInfo.title}
  </h3>

  <article className="blog-post"> 
  <div> 
  <pre style={{ whiteSpace: 'pre-wrap' }}>{fileInfo.text}</pre>
 
  {/* <p>File URL: {fileUrl}</p> */}
</div>

  </article>
 

</div>}
</>
 );
}

// function TextFromFile({url}) {
//   // State to store the contents of the text file
//   const [fileContent, setFileContent] = useState('');
//   const [fileTitle, setFileTitle] = useState('');

//   useEffect(() => {
//     // Function to fetch the contents of the text file
//     const fetchTextFile = async () => {
//       try {
//         // Fetch the text file
//         const response = await fetch(url);
//         // Get the text content from the response
//         const text = await response.text();
//         const title = text.trim().split('\n')[0];
//         // Update the state with the text content
//         setFileContent(text);
//         setFileTitle(title);
//       } catch (error) {
//         console.error('Error fetching text file:', error);
//       }
//     };

//     // Call the fetchTextFile function when the component mounts
//     fetchTextFile();

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []); // Empty dependency array to run the effect only once when the component mounts

//   return (
//     <div>
//      <h1>{fileTitle}</h1>
//       <div style={{ fontSize:'11px',whiteSpace: 'pre-wrap' }}>{fileContent}</div>
//     </div>
//   );
// }
const DocViewer = forwardRef((props, ref) => {

  // Namespace 
  const {layout,data,getParams}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();
  // Access globally  refs

 
 const [doc,setDoc]=useState(null);
 const [file,setFile]=useState({});

 useEffect(() => {
  
  const src = getParams().src;

  if(src!=null){
      try { 
        setDoc(getParams().src||null);
        // check if exists
        require('../../assets/docs/en.'+getParams().src+'.txt');
      }catch(err){
        setDoc({error:err}); 
      }
    
    }else{
      setDoc(null);
    }

     }, []);
    return ( 

      <div className="bg-body-tertiary pt-1">  
      <div className="container">   
     
    {/* <div className=" text-white   bg-dark">
    <div className="px-0">
      <h1 className="display-4">Title of a longer featured blog post</h1>
      <p className="lead my-1">Multiple line teresting in this post’s contents.</p> 
    </div>
    </div> */}
    
    <div className="container py-4 px-2">

{/* 
  <div className="row mb-2">
    <div className="col-md-6">
      <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          <strong className="d-inline-block mb-2 text-primary">World</strong>
          <h3 className="mb-0">Featured post</h3>
          <div className="mb-1 text-muted">Nov 12</div>
          <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
          <a href="#" className="stretched-link">Continue reading</a>
        </div>
        <div className="col-auto d-none d-lg-block">
          <svg className="bd-placeholder-img" width="200" height="250" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>

        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          <strong className="d-inline-block mb-2 text-success">Design</strong>
          <h3 className="mb-0">Post title</h3>
          <div className="mb-1 text-muted">Nov 11</div>
          <p className="mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
          <a href="#" className="stretched-link">Continue reading</a>
        </div>
        <div className="col-auto d-none d-lg-block">
          <svg className="bd-placeholder-img" width="200" height="250" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>

        </div>
      </div>
    </div>
  </div> */}

 { doc && <div className="row g-5">
  {  !doc.error && <CustomFileReader src={require('../../assets/docs/en.'+doc+'.txt')} />  }
      { doc.error && <div >Resource invalid</div>  }

    <div className="col-md-4">
 
      <RelatedDocs/>
       
    </div>
  </div>}

  {!doc &&   <>
    
     <RelatedDocs/>
   </> }

      </div>

 

     </div>
   </div>
 
    );
});
export default DocViewer;