import React, { useEffect, forwardRef } from 'react'; 
import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  
 
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';
import RelatedDocs from './RelatedDocs' 
// import Slider from './Slider'
 
import imgHeader from '../assets/logo/full-gb.svg';  
import qrZalo from '../assets/img/qr/qr-zalo.jpg';  
import qrWechat from '../assets/img/qr/qr-wechat.jpg'; 

 import useFile from '@iorp/react-aid/lib/hooks/useFile';  
 function CustomFileReader({src}) {
  const { fileContent, fileUrl, isLoading, error } = useFile(src);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {/* <h2>File Content:</h2> */}
      {/* Render the file content preserving line breaks */}
      <pre style={{ whiteSpace: 'pre-wrap' }}>{fileContent}</pre>
      {/* Render the file URL */}
      <p>File URL: {fileUrl}</p>
    </div>
  );
}
 


const Contact = forwardRef((props, ref) => {

  // Namespace 
  const {layout,data,options}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();
  // Access globally  refs

 

    return ( 
      <>

        

        {/* <div className="p-4 p-md-5 text-white   bg-dark">
    <div className="px-0">
      <h1 className="display-4">Title of a longer featured blog post</h1>
      <p className="lead my-1">Multiple line teresting in this post’s contents.</p> 
    </div>
  </div> */}

{/* <Slider/> */}

<div className="container py-4">

<div className="row mt-4">
<div className="col-md-12 mb-4">
<h1>{options.companyData.name} <small>{L('Vietnam')}</small></h1>
{/* ow p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-0 border shadow-lg */}
{/* <small muted>{L('ContactUsWithAny')}</small> */}
</div>

  <div className="col-md-4 mb-4">
    <div className="card border rounded-0 shadow-lg ">
      <div className="card-body">
        <i className="fas fa-shopping-cart fa-3x mb-3"></i>
        <h5 className="card-title">{L('Sales')}</h5>
        <p className="card-text">{L('ForSalesInquiries')}</p>
        <p>{L('Email')}: {options.companyData.salesMail}</p>
        <p>{L('Phone')}:{options.companyData.salesPhone}</p>
      </div>
    </div>
  </div>
  
  <div className="col-md-4 mb-4">
    <div className="card border rounded-0 shadow-lg">
      <div className="card-body">
        <i className="fas fa-shopping-cart fa-3x mb-3"></i>
        <h5 className="card-title">{L('Support')}</h5>
        <p className="card-text">{L('ForSupportAssistance')}</p>
        <p>{L('Email')}: {options.companyData.supportMail}</p>
        <p>{L('Phone')}:{options.companyData.supportPhone}</p>
      </div>
    </div>
  </div>

  <div className="col-md-4 mb-4">
    <div className="card border rounded-0 shadow-lg">
      <div className="card-body">
        <i className="fas fa-shopping-cart fa-3x mb-3"></i>
        <h5 className="card-title">{L('Exports')}</h5>
        <p className="card-text">{L('ForExportsAssistance')}</p>
        <p>{L('Email')}: {options.companyData.exportsMail}</p>
        <p>{L('Phone')}:{options.companyData.exportsPhone}</p>
      </div>
    </div>
  </div>

   
</div>


    <div className="row mt-5">
      <div className="col-md-9">
        {/* <h1>{options.companyData.name} <small>{L('Vietnam')}</small></h1> */}
        {/* <h1> {L('TellOurTeams')}</h1> */}
        {/* <img src="logo.png" alt="Company Logo" className="img-fluid mt-3"/> */}
        <img src={imgHeader} alt={options.companyData.name} className="img-fluid mt-3" />
        <small muted>{L('ContactUsWithAny')}</small>
        <div className="mt-2">
 
        <p>{L('Sales')}: {options.companyData.salesMail} {options.companyData.salesPhone}  </p>
        <p>{L('Support')}: {options.companyData.supportMail} {options.companyData.supportPhone}  </p>  
        <p>{L('Exports')}: {options.companyData.exportsMail} {options.companyData.exportsPhone}  </p>  
        <p>{L('MainAddress')}: {options.companyData.address}</p>

      </div>


       
 
        <iframe src={options.companyData.addressMaps}   style={{width:'-webkit-fill-available',border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>  
      </div>
      <div className="col-md-3">
      <div className="row mt-5">
      <div className="col-sm-6 col-md-12 ">
      <div className="card mb-4">
      <div className="card-header text-muted text-center"> Zalo  </div>
        <img src={qrZalo} className="p-4 card-img-top" alt="Zalo"/> 
      </div>
      </div>
      <div className="col-sm-6 col-md-12">
      <div className="card mb-4">
      <div className="card-header text-muted text-center"> Wechat  </div>
        <img src={qrWechat} className="p-4 card-img-top" alt="Wechat"/> 
      </div>
      </div>

        </div>
        </div>
    </div> 
    
        {/* <h2>{L('ContactForm')}</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">{L('Name')}</label>
            <input type="text" className="form-control" id="name" required/>
          </div>
          <div className="form-group">
            <label htmlFor="email">{L('Email')}</label>
            <input type="email" className="form-control" id="email" required/>
          </div>
          <div className="form-group">
            <label htmlFor="message">{L('Message')}</label>
            <textarea className="form-control" id="message" rows="5" required></textarea>
          </div>
          <button type="submit" className="btn btn-success">{L('Submit')}</button>
        </form> */}
   

      {/* <div className="container pt-4">
  
      <div className="card w-50 p-3">
  <div className="card-body">
    <h5 className="card-title">Card title</h5>
  </div>
  <div className="ratio ratio-1x1">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d356933.8714888409!2d10.413661869378636!3d45.65994086120074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781eca8aec020b9%3A0x91dcf07c1c969bb8!2sGarda!5e0!3m2!1spl!2spl!4v1672244147501!5m2!1spl!2spl" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</div>




<section className="bg-light py-3 py-md-5">
  <div className="container">
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 className="mb-4 display-5 text-center">Contact</h2>
        <p className="text-secondary mb-5 text-center">The best way to contact us is to use our contact form below. Please fill out all of the required fields and we will get back to you as soon as possible.</p>
        <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div className="container">
    <div className="row justify-content-lg-center">
      <div className="col-12 col-lg-9">
        <div className="bg-white border rounded shadow-sm overflow-hidden">

          <form action="#!">
            <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
              <div className="col-12">
                <label htmlFor="fullname" className="form-label">Full Name <span className="text-danger">*</span></label>
                <input type="text" className="form-control" id="fullname" name="fullname" value="" required/>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                <div className="input-group">
                  <span className="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </span>
                  <input type="email" className="form-control" id="email" name="email" value="" required/>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="phone" className="form-label">Phone Number</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                  </span>
                  <input type="tel" className="form-control" id="phone" name="phone" value=""/>
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="message" className="form-label">Message <span className="text-danger">*</span></label>
                <textarea className="form-control" id="message" name="message" rows="3" required></textarea>
              </div>
              <div className="col-12">
                <div className="d-grid">
                  <button className="btn btn-primary btn-lg" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</section>
       */}
       </div>
      </>
    );
});
export default Contact;

