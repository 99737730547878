
 import React, { createRef, useEffect, forwardRef} from 'react'; 
 import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  
 import 'bootstrap/dist/css/bootstrap.min.css';  
  
     
   import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';
 
  
 import Footer from '../../components/Footer';
 import Topbar from '../../components/Topbar';   
 import ScrollToTop from '../../components/ScrollToTop';
 

 require('./../../style.css'); // global style 
 require('./style.css'); // layout style s
 
   
 
  
 const Layout = (props) => { 
   const { refs,setRefs,getParams } = useNamespace();
   const {locale,L,setLanguage,setStrings,getString} = useLocale()
 
 
   useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scrolling in body

    // set namespaceOptions from config.js
   // setOptions(deepMerge(options,namespaceOptions));
 
   // Get strings
   setStrings({en:require('../../strings/en.json')});
   setStrings({vn:require('../../strings/vn.json')});
   setStrings({ru:require('../../strings/ru.json')});
 
   // set the language by url parameter 
    const languageUrlParam = getParams().l;
    if(languageUrlParam)setLanguage(languageUrlParam);
     // create layout refs as object 
 
     setRefs({
       topbar:createRef(), 
       main:createRef(),  
       footer:createRef(), 
   }) ;
   }, []);
 
  
    
   return (
    
       refs && <>
 
       <ScrollToTop/>
       
     <Topbar ref={refs.topbar}  />
    
  <div className="main main-fixed oscroll oscroll-w10 oscroll-br-10 oscroll-bg-black" ref={refs.main} 
 
    >
 
  {props.children}
 
 <Footer ref={refs.footer}/>
  
 </div>
   
      </> 
   );
 }

 
 const LayoutWrapper = forwardRef((props, ref) => {
    return (
      
             <Layout {...props}/>
        
          )
        });
  
 
   export default LayoutWrapper;
  
  
  