import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleUp } from '@fortawesome/free-solid-svg-icons'; 

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show/hide scroll to top button based on scroll position
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Button
      className="btn-dark position-fixed bottom-0 end-0 mb-3 me-3"
      onClick={scrollToTop}
      style={{zIndex:999, display: isVisible ? 'block' : 'none' }}
    >
     <FontAwesomeIcon icon={faAngleUp} />
    </Button>
  );
};

export default ScrollToTopButton;
