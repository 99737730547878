 
 // Import necessary dependencies from the React library
import React, {  forwardRef } from 'react'; 
import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';  
// Import Bootstrap styling
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Button,Container,Nav} from 'react-bootstrap';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark,faBars } from '@fortawesome/free-solid-svg-icons'; 


import Navbar from '@iorp/react-aid/lib/components/Navbar'; 
 
 
//  
      
// import logoFull from '../../assets/logo/full-gw.svg';
// import logoIcon from '../../assets/logo/icon-g.svg';
// import logoText from '../../assets/logo/text-w.svg';


 
import LanguageSwitcher from '../LanguageSwitcher'
const Topbar = forwardRef((props, ref) => {
  const {locale,L,setLanguage,setStrings,getString} = useLocale()
  const { refs ,options} = useNamespace();  
 

    return (

        <Navbar ref={ref} style={{    position: 'static',zIndex:'0!important'}} bg="dark" data-bs-theme="dark"  className={'fixed-top bg-dark navbar-dark '}>
        <Container className='d-flex  flex-row flex-drawer-column' fluid>  
         
        
        
          <Nav className="justify-content-start flex-grow-1 flex-row flex-drawer-column ">
          {/* <Button className={'d-drawer-none'} variant="dark"   
           onClick={() => {refs.leftbar && refs.leftbar.current.toggle()}}
           >
          <FontAwesomeIcon icon={faBars} />
          </Button> */}
 

    
    
    {/* DYNAMIC LOGO 
    <a className="d-drawer-none" style={{paddingTop:4}}>
    <div className=" d-sm-none ">
    <img src={logoIcon} style={{height:32,width:32}}/>
    </div>
     
    <div className=" d-none d-sm-block ">
    <img src={logoFull} style={{height:32,width:'auto'}}/>
    </div>
    </a> */}


    <a href={process.env.REACT_APP_HOMEPAGE+'?h:no'} className="d-drawer-none" style={{paddingTop:4}}>
     
    <img src={require('../../assets/logo/full-gw.svg').default} style={{height:32,width:'auto'}}/>
    
    </a>

 

          {/* <Button className="d-drawer-none" variant="dark"  >   {L('brandName')}    </Button> */}
           
        
          <div className="d-bar-none"  style={{position:'absolute',top:0,right:0}} > 
         <Button    onClick={()=>{ref.current.toggleDrawer()}} variant="transparent"><FontAwesomeIcon icon={faXmark} /></Button>
        </div> 
        

        <Nav.Item className="d-none  d-drawer-block ">    <Nav.Link href={process.env.REACT_APP_HOMEPAGE+'?h:no'}>{L('Home')}</Nav.Link>  </Nav.Item>
        <Nav.Item className="d-none d-bar-md-block d-drawer-block ">    <Nav.Link href={'products'}>{L('Products')}</Nav.Link>  </Nav.Item>
        <Nav.Item className="d-none d-bar-md-block d-drawer-block ">    <Nav.Link href={'about'}>{L('AboutUs')}</Nav.Link>  </Nav.Item>
        <Nav.Item className="d-none d-bar-md-block d-drawer-block ">    <Nav.Link href={'docs'}>{L('Docs')}</Nav.Link>  </Nav.Item>
        {/* <Nav.Item className="d-none d-bar-md-block d-drawer-block ">    <Nav.Link href={'news'}>{L('News')}</Nav.Link>  </Nav.Item> */}
        <Nav.Item className="d-none d-bar-md-block d-drawer-block ">    <Nav.Link href={'contact'}>{L('Contact')}</Nav.Link>  </Nav.Item>
         
          {/* <Nav.Item>
          <Nav.Link className="d-none d-bar-sm-block d-drawer-none" >D</Nav.Link>
          </Nav.Item> 
          <Nav.Item>
          <Nav.Link className="d-block d-bar-sm-none d-drawer-none">E </Nav.Link>
          </Nav.Item> */}
        
          {/* <Nav.Item>
          <Nav.Link className="d-none d-bar-lg-block d-drawer-none" >F</Nav.Link>
          </Nav.Item> */}
          </Nav>
        
         </Container> 
         <Container className='d-flex flex-row flex-drawer-column' style={{whiteSpace:'nowrap',flex:1}} fluid>   
         <Nav className="justify-content-end flex-grow-1 flex-row flex-drawer-column">
         <Nav.Item className="d-none d-bar-md-block d-drawer-block ">    <Nav.Link  href={'login'}>{L('Associates')}</Nav.Link>  </Nav.Item>

         {/* <Nav.Item>
         <Nav.Link href="login" className="d-none d-bar-md-block d-drawer-block" >{L('Associates')}</Nav.Link>
         </Nav.Item> */}
         {/* <Nav.Item>
         <Nav.Link className="d-none d-bar-lg-block">V </Nav.Link>
         </Nav.Item> */}
         </Nav>
{/*         
         <Nav className="justify-content-end flex-grow-1 flex-row flex-drawer-column">
         <Nav.Item>
         <Nav.Link className="d-bar-none" >W</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link  className="d-drawer-none">X</Nav.Link>
         </Nav.Item>
         </Nav>
        
         <Nav className="justify-content-end flex-grow-1 flex-grow-1 flex-row flex-drawer-column ">
         <Nav.Item>
         <Nav.Link  className="d-none d-bar-lg-block d-drawer-sm-block" >Y</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link className="">Z</Nav.Link>
         </Nav.Item>
         </Nav>
        
         
         */}
        
        </Container>
        <Button className={'d-block d-bar-md-none d-drawer-none'} variant="dark"   
          onClick={() => {refs.topbar && refs.topbar.current.toggleDrawer()}}
          >
         <FontAwesomeIcon icon={faBars} />
         </Button>
        
        
         {/* <LanguageSwitcherDropdown  drop={'down'} align={'end'} /> */}
         <LanguageSwitcher className="d-drawer-none"  drop={'down'} align={'end'} />
        
        
            </Navbar> 

    );
});
export default Topbar;
 
 