import React, { useState, useEffect } from 'react';

function NewsFeed({ src }) {
  const [newsItems, setNewsItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
      //  const response = await fetch(src);
       
       const response = {
        ok:true,
        text:()=>{
            return `<?xml version="1.0" encoding="UTF-8"?><rss version="2.0"
            xmlns:content="http://purl.org/rss/1.0/modules/content/"
            xmlns:wfw="http://wellformedweb.org/CommentAPI/"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:atom="http://www.w3.org/2005/Atom"
            xmlns:sy="http://purl.org/rss/1.0/modules/syndication/"
            xmlns:slash="http://purl.org/rss/1.0/modules/slash/"
            
            xmlns:georss="http://www.georss.org/georss"
            xmlns:geo="http://www.w3.org/2003/01/geo/wgs84_pos#"
            >
        
        <channel>
            <title>ICC &#8211; International Chamber of Commerce</title>
            <atom:link href="https://iccwbo.org/feed/" rel="self" type="application/rss+xml" />
            <link>https://iccwbo.org/</link>
            <description>ICC (International Chamber of Commerce) is the voice of world business championing the global economy as a force for economic growth, job creation and prosperity.</description>
            <lastBuildDate>Thu, 18 Apr 2024 16:42:40 +0000</lastBuildDate>
            <language>en-US</language>
            <sy:updatePeriod>
            hourly	</sy:updatePeriod>
            <sy:updateFrequency>
            1	</sy:updateFrequency>
            
        
        <image>
            <url>https://iccwbo.org/wp-content/uploads/sites/3/2023/04/cropped-favicon_icc.png?w=32</url>
            <title>ICC &#8211; International Chamber of Commerce</title>
            <link>https://iccwbo.org/</link>
            <width>32</width>
            <height>32</height>
        </image> 
            <item>
                <title>Study shows WTO collapse could slash exports of developing countries by 33%</title>
                <link>https://iccwbo.org/news-publications/policies-reports/study-shows-wto-collapse-could-slash-exports-of-developing-countries-by-33-percent/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Wed, 17 Apr 2024 10:59:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519682</guid>
        
                            <description><![CDATA[<p>New economic modelling shows developing economies would see significant long-term trade reductions without the safety-net of the World Trade Organization (WTO). </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/policies-reports/study-shows-wto-collapse-could-slash-exports-of-developing-countries-by-33-percent/">Study shows WTO collapse could slash exports of developing countries by 33%</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC recognises Dubai Chambers’ pioneering Chamber Model Innovation</title>
                <link>https://iccwbo.org/news-publications/news/icc-recognises-dubai-chambers-pioneering-chamber-model-innovation/</link>
                
                <dc:creator><![CDATA[randa]]></dc:creator>
                <pubDate>Wed, 17 Apr 2024 10:57:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519697</guid>
        
                            <description><![CDATA[<p>Dubai Chambers was recognised by ICC for its contribution to the development of the Chamber Model Innovation framework, designed to fast-track chamber-led innovation, during a visit to ICC Global Headquarters in Paris on 12 April.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-recognises-dubai-chambers-pioneering-chamber-model-innovation/">ICC recognises Dubai Chambers’ pioneering Chamber Model Innovation</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>New report highlights continued threat of Somali piracy</title>
                <link>https://iccwbo.org/news-publications/news/new-report-highlights-continued-threat-of-somali-piracy/</link>
                
                <dc:creator><![CDATA[randa]]></dc:creator>
                <pubDate>Wed, 10 Apr 2024 09:17:39 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519339</guid>
        
                            <description><![CDATA[<p>The ICC International Maritime Bureau (IMB) raises concern on the continued acts of maritime piracy off the coast of Somalia in its first quarter report for 2024, released today.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/new-report-highlights-continued-threat-of-somali-piracy/">New report highlights continued threat of Somali piracy</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC Secretary General addresses environment ministers ahead of INC-4</title>
                <link>https://iccwbo.org/news-publications/news/icc-secretary-general-addresses-environment-ministers-ahead-of-inc-4/</link>
                
                <dc:creator><![CDATA[joachimd]]></dc:creator>
                <pubDate>Tue, 09 Apr 2024 14:28:49 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519328</guid>
        
                            <description><![CDATA[<p>ICC Secretary General John W.H. Denton AO has issued a letter to environment ministers, calling on them to prioritise tangible outcomes at the upcoming 4th negotiating session of the UNEP Intergovernmental Negotiating Committee (INC-4) to combat plastic pollution. With only two sessions remaining, Mr. Denton emphasises the urgency of forging a clear path forward and mobilising all sectors of society to address this pressing global issue.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-secretary-general-addresses-environment-ministers-ahead-of-inc-4/">ICC Secretary General addresses environment ministers ahead of INC-4</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>New Leadership Appointed to DSI Industry Advisory Board</title>
                <link>https://iccwbo.org/news-publications/news/new-leadership-appointed-to-dsi-industry-advisory-board/</link>
                
                <dc:creator><![CDATA[joachimd]]></dc:creator>
                <pubDate>Wed, 27 Mar 2024 09:32:11 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518932</guid>
        
                            <description><![CDATA[<p>The International Chamber of Commerce (ICC) has announced new appointments to lead the Industry Advisory Board (IAB) of the Digital Standards Initiative, the global platform for digital trade standards alignment, adoption and engagement.  </p>
        <p>Stephan Wolf of GLEIF assumes the Chair role, as the inaugural Chair Gerard Hartsink steps down. Robert Beideman of GS1, continues in his current role as Vice-Chair and is joined by ING banker Lynn Ng as newly appointed Vice-Chair. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/new-leadership-appointed-to-dsi-industry-advisory-board/">New Leadership Appointed to DSI Industry Advisory Board</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Digital tools central for development of MSME trade in Latin America </title>
                <link>https://iccwbo.org/news-publications/policies-reports/msmes-digital-exports-in-latin-america/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Mon, 25 Mar 2024 16:38:48 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519099</guid>
        
                            <description><![CDATA[<p>Digital tools and technologies play a crucial role in boosting trade for micro-, small-, and medium-sized enterprises (MSMEs) in Latin America, reveals a new ICC report.   </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/policies-reports/msmes-digital-exports-in-latin-america/">Digital tools central for development of MSME trade in Latin America </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>New version of Global Trade Modernization Index launches to accelerate digital trade </title>
                <link>https://iccwbo.org/news-publications/news/new-version-of-global-trade-modernization-index-launches-to-accelerate-digital-trade/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Mon, 25 Mar 2024 16:33:55 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519086</guid>
        
                            <description><![CDATA[<p>The ICC Digital Standards Initiative, the Asia Global Institute and Milken Institute have announced the launch of the BETA version of the Global Trade Modernization Index (GTMI) in Hong Kong. The new version marks the first update of the ALPHA version launched in March 2023.   </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/new-version-of-global-trade-modernization-index-launches-to-accelerate-digital-trade/">New version of Global Trade Modernization Index launches to accelerate digital trade </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC wins award for advancing inclusivity in arbitration</title>
                <link>https://iccwbo.org/news-publications/news/icc-wins-award-for-advancing-inclusivity-in-arbitration/</link>
                
                <dc:creator><![CDATA[joachimd]]></dc:creator>
                <pubDate>Fri, 22 Mar 2024 11:46:28 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=519047</guid>
        
                            <description><![CDATA[<p>ICC has been awarded the Equal Representation in Arbitration (ERA) Pledge Award for exceptional contributions to promoting diversity and inclusion. The announcement was made last night at the prestigious Global Arbitration Review (GAR) Awards ceremony in Paris. It is the second time ICC has been recognised in this category.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-wins-award-for-advancing-inclusivity-in-arbitration/">ICC wins award for advancing inclusivity in arbitration</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>New ICC Hearing Centre unveiled</title>
                <link>https://iccwbo.org/news-publications/news/new-icc-hearing-centre-unveiled/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Mon, 18 Mar 2024 10:00:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518854</guid>
        
                            <description><![CDATA[<p>ICC has announced details on the opening of an ICC Hearing Centre in Paris, including its location, set-up and expected opening date.  </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/new-icc-hearing-centre-unveiled/">New ICC Hearing Centre unveiled</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC warns of multilateral trade challenges in CNBC interview</title>
                <link>https://iccwbo.org/news-publications/news/icc-warns-of-multilateral-trade-challenges/</link>
                
                <dc:creator><![CDATA[randa]]></dc:creator>
                <pubDate>Fri, 15 Mar 2024 15:38:23 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518865</guid>
        
                            <description><![CDATA[<p>ICC Secretary General John W.H. Denton AO provided insights into the developments emerging from the World Trade Organization’s (WTO) Ministerial Meeting (MC13) in Abu Dhabi. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-warns-of-multilateral-trade-challenges/">ICC warns of multilateral trade challenges in CNBC interview</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC responds to MC13</title>
                <link>https://iccwbo.org/news-publications/news/icc-responds-to-mc13/</link>
                
                <dc:creator><![CDATA[dawn]]></dc:creator>
                <pubDate>Sat, 02 Mar 2024 11:48:15 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518712</guid>
        
                            <description><![CDATA[<p>ICC has issued a statement upon conclusion of the World Trade Organization's 13th Ministerial Conference in Abu Dhabi.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-responds-to-mc13/">ICC responds to MC13</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Global Business Priorities for the World Trade Organization and MC13</title>
                <link>https://iccwbo.org/news-publications/policies-reports/global-business-priorities-for-the-world-trade-organization/</link>
                
                <dc:creator><![CDATA[dnatalija]]></dc:creator>
                <pubDate>Wed, 28 Feb 2024 14:52:34 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?post_type=publication&#038;p=442221</guid>
        
                            <description><![CDATA[<p>In the lead up to the 13th World Trade Organization Ministerial Conference (MC13), ICC sets out the private sector’s priorities and recommendations to make the multilateral trading system work better for people and the planet. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/policies-reports/global-business-priorities-for-the-world-trade-organization/">Global Business Priorities for the World Trade Organization and MC13</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>2024: Empowering Indigenous Peoples&#8217; Economic Development through International Trade </title>
                <link>https://iccwbo.org/news-publications/news/2024-empowering-indigenous-peoples-economic-development-through-international-trade/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Thu, 22 Feb 2024 08:43:32 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518378</guid>
        
                            <description><![CDATA[<p>The International Chamber of Commerce (ICC), the International Trade Centre (ITC), and the World Trade Organization’s Informal Working Group on Micro, Small and Medium-Sized Enterprises (WTO MSME Group), with the support of the World Intellectual Property Organization (WIPO), are calling for innovative ideas that support international trade by Indigenous Peoples. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/2024-empowering-indigenous-peoples-economic-development-through-international-trade/">2024: Empowering Indigenous Peoples&#8217; Economic Development through International Trade </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC Secretary General John Denton’s open letter to trade ministers ahead of MC13</title>
                <link>https://iccwbo.org/news-publications/statement-letters/icc-secretary-general-john-dentons-open-letter-to-trade-ministers-ahead-of-mc13/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Wed, 21 Feb 2024 10:48:21 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518432</guid>
        
                            <description><![CDATA[<p>ICC Secretary General John W.H. Denton AO has issued a letter to trade ministers urging them to make every possible effort to ensure that the World Trade Organization’s 13th Ministerial Conference (MC13) delivers tangible outcomes to bolster the multilateral trading system. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/statement-letters/icc-secretary-general-john-dentons-open-letter-to-trade-ministers-ahead-of-mc13/">ICC Secretary General John Denton’s open letter to trade ministers ahead of MC13</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC Forum seeks young regional representatives </title>
                <link>https://iccwbo.org/news-publications/news/icc-forum-seeks-young-regional-representatives/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Mon, 19 Feb 2024 15:49:41 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518418</guid>
        
                            <description><![CDATA[<p>The ICC Young Arbitration and ADR Forum (ICC YAAF) has launched a campaign to recruit young practitioners in the field of dispute resolution and avoidance as new regional representatives for its 2024-2026 mandate.  </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-forum-seeks-young-regional-representatives/">ICC Forum seeks young regional representatives </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Law Society of Ireland wins 2024 ICC Mediation Competition </title>
                <link>https://iccwbo.org/news-publications/news/law-society-of-ireland-wins-2024-icc-mediation-competition/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Mon, 12 Feb 2024 16:39:46 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518358</guid>
        
                            <description><![CDATA[<p>The Law Society of Ireland team took first place in the 19th ICC International Commercial Mediation Competition, defeating a team from Germany’s University of Marburg during the final mediation session. Vrije Universiteit Amsterdam from The Netherlands and the National University of Singapore were competition runner ups. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/law-society-of-ireland-wins-2024-icc-mediation-competition/">Law Society of Ireland wins 2024 ICC Mediation Competition </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC announces agri-food scholarship recipient</title>
                <link>https://iccwbo.org/news-publications/news/icc-announces-agri-food-scholarship-recipient/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Wed, 07 Feb 2024 14:52:40 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518210</guid>
        
                            <description><![CDATA[<p>Nana Achiaa Addai, a Senior Associate at Adu-Kusi Pruc and a member of ICC Ghana, has been named the recipient of a fully funded scholarship made available to ICC members through the ICC Agri-food initiative. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-announces-agri-food-scholarship-recipient/">ICC announces agri-food scholarship recipient</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Global Industry Statement on the WTO Moratorium on Customs Duties on Electronic Transmissions</title>
                <link>https://iccwbo.org/news-publications/policies-reports/global-industry-statement-on-the-wto-moratorium-on-customs-duties-on-electronic-transmissions/</link>
                
                <dc:creator><![CDATA[dnatalija]]></dc:creator>
                <pubDate>Fri, 02 Feb 2024 10:47:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?post_type=publication&#038;p=446251</guid>
        
                            <description><![CDATA[<p>The International Chamber of Commerce (ICC) has joined a global coalition of industry associations in a united call to renew the pivotal digital safeguard.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/policies-reports/global-industry-statement-on-the-wto-moratorium-on-customs-duties-on-electronic-transmissions/">Global Industry Statement on the WTO Moratorium on Customs Duties on Electronic Transmissions</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>DSI Legal Reform Advisory Board announces new Chair</title>
                <link>https://iccwbo.org/news-publications/news/dsi-legal-reform-advisory-board-announces-new-chair/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Fri, 02 Feb 2024 09:05:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518100</guid>
        
                            <description><![CDATA[<p>The International Chamber of Commerce (ICC) Digital Standards Initiative (DSI) has announced the appointment of Oliver Wieck, Secretary General of ICC Germany, as the new Chair of the DSI Legal Reform Advisory Board.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/dsi-legal-reform-advisory-board-announces-new-chair/">DSI Legal Reform Advisory Board announces new Chair</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC to guide business priorities for G20</title>
                <link>https://iccwbo.org/news-publications/news/icc-to-guide-business-priorities-for-g20/</link>
                
                <dc:creator><![CDATA[randa]]></dc:creator>
                <pubDate>Thu, 01 Feb 2024 14:05:25 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=518069</guid>
        
                            <description><![CDATA[<p>Four ICC leaders will be co-leading task forces of the B20 group, the platform for the international business community to support the work of the G20 process organised under the stewardship of host country Brazil this year.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-to-guide-business-priorities-for-g20/">ICC to guide business priorities for G20</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Regional Summits to intensify chamber impact</title>
                <link>https://iccwbo.org/news-publications/news/regional-summits-to-intensify-chamber-impact/</link>
                
                <dc:creator><![CDATA[joachimd]]></dc:creator>
                <pubDate>Fri, 26 Jan 2024 13:00:31 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517979</guid>
        
                            <description><![CDATA[<p>The ICC World Chambers Federation has unveiled a groundbreaking series of Regional Summits aimed at tackling global challenges through a regional perspective.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/regional-summits-to-intensify-chamber-impact/">Regional Summits to intensify chamber impact</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Celebrating World Customs Day 2024</title>
                <link>https://iccwbo.org/news-publications/news/celebrating-world-customs-day-2024/</link>
                
                <dc:creator><![CDATA[joachimd]]></dc:creator>
                <pubDate>Fri, 26 Jan 2024 11:03:25 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517971</guid>
        
                            <description><![CDATA[<p>It’s World Customs Day, celebrated in 2024 under the theme ‘Customs Engaging Traditional and New Partners with Purpose.’</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/celebrating-world-customs-day-2024/">Celebrating World Customs Day 2024</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Clock is ticking for future of e-commerce and digital trade  </title>
                <link>https://iccwbo.org/news-publications/news/clock-is-ticking-for-future-of-e-commerce-and-digital-trade/</link>
                
                <dc:creator><![CDATA[randa]]></dc:creator>
                <pubDate>Wed, 24 Jan 2024 14:35:56 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517933</guid>
        
                            <description><![CDATA[<p>A World Trade Organization (WTO) moratorium on customs duties on electronic transmissions is set to expire in February. Without its renewal, the future of e-commerce and digital trade hangs in the balance.  </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/clock-is-ticking-for-future-of-e-commerce-and-digital-trade/">Clock is ticking for future of e-commerce and digital trade  </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC India Arbitration Group announces new Chair</title>
                <link>https://iccwbo.org/news-publications/news/icc-india-arbitration-group-announces-new-chair/</link>
                
                <dc:creator><![CDATA[joachimd]]></dc:creator>
                <pubDate>Mon, 22 Jan 2024 09:10:38 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517806</guid>
        
                            <description><![CDATA[<p>ICC has appointed Shashank Garg, an independent counsel and arbitrator, as new Chair of the ICC India Arbitration Group (IAG). Under a strategic transformation, the group will be reconstituted with a focus on strengthening the arbitration community in India, further advancing India’s interest in promoting Indian cities as seats of arbitration and promoting ICC Dispute Resolution Services in India. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-india-arbitration-group-announces-new-chair/">ICC India Arbitration Group announces new Chair</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC to promote Shanghai arbitration capacity building </title>
                <link>https://iccwbo.org/news-publications/news/icc-to-promote-shanghai-arbitration-capacity-building/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Wed, 17 Jan 2024 12:18:22 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517766</guid>
        
                            <description><![CDATA[<p>ICC has welcomed measures introduced by the Shanghai Municipal People’s Government aimed at strengthening Shanghai as an international legal services hub. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-to-promote-shanghai-arbitration-capacity-building/">ICC to promote Shanghai arbitration capacity building </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>ICC brings Advanced Arbitration Academy programme to Africa and Middle East </title>
                <link>https://iccwbo.org/news-publications/news/icc-brings-advanced-arbitration-academy-programme-to-africa-and-middle-east/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Mon, 15 Jan 2024 14:59:45 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517700</guid>
        
                            <description><![CDATA[<p>Following successful launches in Asia, Eastern Europe, Latin America and North America in 2023, ICC is expanding its Advanced Arbitration Academies training programme to include Africa and the Middle East. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/icc-brings-advanced-arbitration-academy-programme-to-africa-and-middle-east/">ICC brings Advanced Arbitration Academy programme to Africa and Middle East </a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>New IMB report reveals concerning rise in maritime piracy incidents in 2023</title>
                <link>https://iccwbo.org/news-publications/news/new-imb-report-reveals-concerning-rise-in-maritime-piracy-incidents-in-2023/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Thu, 11 Jan 2024 08:00:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517648</guid>
        
                            <description><![CDATA[<p>Released today, the annual Piracy and Armed Robbery Report of the ICC International Maritime Bureau (IMB) raises concern over the first successful Somali based hijacking since 2017. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/new-imb-report-reveals-concerning-rise-in-maritime-piracy-incidents-in-2023/">New IMB report reveals concerning rise in maritime piracy incidents in 2023</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Mexican small business entrepreneur shows how sustainability reporting tool fits the bill</title>
                <link>https://iccwbo.org/news-publications/news/mexican-small-business-entrepreneur-shows-how-sustainability-reporting-tool-fits-the-bill/</link>
                
                <dc:creator><![CDATA[caroline]]></dc:creator>
                <pubDate>Thu, 21 Dec 2023 10:28:23 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517476</guid>
        
                            <description><![CDATA[<p>The demand for measuring the environmental impact of SMEs is growing, and so is the need for SMEs to go green. With the assessment tool SME360X and supporting initiatives like the UPS Green Exporters Program, ICC, Gist Impact and UPS aim to make sustainable business a reality for all. In this series, we share stories of SMEs, like Mexican artisan textile business Marieta Linda, as they champion the green transition in their day-to-day business.</p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/mexican-small-business-entrepreneur-shows-how-sustainability-reporting-tool-fits-the-bill/">Mexican small business entrepreneur shows how sustainability reporting tool fits the bill</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>How a small handicrafts business in India is measuring its environmental impact</title>
                <link>https://iccwbo.org/news-publications/news/how-a-small-handicrafts-business-in-india-is-measuring-its-environmental-impact/</link>
                
                <dc:creator><![CDATA[caroline]]></dc:creator>
                <pubDate>Tue, 19 Dec 2023 11:03:00 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517470</guid>
        
                            <description><![CDATA[<p>The demand for measuring the environmental impact of SMEs is growing, and so is the need for SMEs to go green. With the assessment tool SME360X and supporting initiatives like the UPS Green Exporters Program, ICC, Gist Impact and UPS aim to make sustainable business a reality for all. In this series, we share stories of SMEs, like Indian handcrafts business Uppi’s Craft, as they champion the green transition in their day-to-day business. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/news/how-a-small-handicrafts-business-in-india-is-measuring-its-environmental-impact/">How a small handicrafts business in India is measuring its environmental impact</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
                <item>
                <title>Guest blog: How will AI impact dispute resolution?</title>
                <link>https://iccwbo.org/news-publications/guests-blog-speeches/guest-blog-how-will-ai-impact-dispute-resolution/</link>
                
                <dc:creator><![CDATA[audrey]]></dc:creator>
                <pubDate>Tue, 19 Dec 2023 09:11:03 +0000</pubDate>
                        <guid isPermaLink="false">https://iccwbo.org/?p=517427</guid>
        
                            <description><![CDATA[<p>In this guest blog, FTI Consulting Senior Managing Directors Samuel Aguirre, Leonardo Florencio and Karthik Balisagar explore the transformative potential of artificial intelligence (AI) in the legal industry. Revealing insights from a comprehensive survey of over 100 legal practitioners worldwide, the authors explore the current landscape, challenges, and the slow adoption of AI in dispute resolution. With a focus on bridging the knowledge gap and establishing clear guidelines, they shed light on concerns, but also significant benefits AI adoption could bring. </p>
        <p>The post <a rel="nofollow" href="https://iccwbo.org/news-publications/guests-blog-speeches/guest-blog-how-will-ai-impact-dispute-resolution/">Guest blog: How will AI impact dispute resolution?</a> appeared first on <a rel="nofollow" href="https://iccwbo.org">ICC - International Chamber of Commerce</a>.</p>
        ]]></description>
                
                
                
                    </item>
            </channel>
        </rss>`;
        }
       }
       
        if (!response.ok) {
          throw new Error('Failed to fetch RSS feed');
        }
        const xmlText = await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlText, 'text/xml');
        const items = Array.from(xml.querySelectorAll('item'));
        const parsedItems = items.map((item) => ({
          title: item.querySelector('title').textContent,
          link: item.querySelector('link').textContent,
          description: item.querySelector('description').textContent,
          pubDate: item.querySelector('pubDate').textContent,
          // Additional properties you may need
        }));
        setNewsItems(parsedItems);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchNews();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
   
      <div className="container">
        {newsItems.map((item, index) => (
              <article key={index} className="blog-post">
            
            <a style={{    color: 'black',textDecoration: 'none'}}href={item.link} target="_blank" rel="noopener noreferrer">    <h2 className="blog-post-title">{item.title}</h2></a>
            <p className="blog-post-meta">{item.pubDate} by <a href="https://iccwbo.org">iccwbo.org</a></p>
            
               <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
               <hr/>
                </article>
         
        ))}
      </div>
    </div>
  );
}

export default NewsFeed;
