import React, { useState, useEffect ,useContext} from 'react';
  
 
import  Router from '@iorp/react-aid/lib/components/Router' ; 
import  Namespace from '@iorp/react-aid/lib/components/Namespace' ;
import Locale from '@iorp/react-aid/lib/components/Locale';
import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  


 import routes from './routes'
import {localeOptions, namespaceOptions } from './extensions/release/config';


const App = ()=>{ 
  
  return  <Namespace options={namespaceOptions}> 
      <Locale options={localeOptions}>
   
    <Router basename={process.env.REACT_APP_BASENAME} routes={routes}/>  
    </Locale>
    </Namespace>
}  

 
export default App;