import React, { useEffect, forwardRef,createRef } from 'react'; 
import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  
  
 
import imgHeader from '../assets/logo/full-gb.svg';
 

import imgOperations from '../assets/img/operations.png';
import imgIncoterms from '../assets/img/incoterms.jpg';
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWheatAwn,faPeopleCarryBox,faShip,faBoxOpen } from '@fortawesome/free-solid-svg-icons'; 


import HeroVideoCover from './HeroVideoCover';
const Home = forwardRef((props, ref) => {

  // Namespace 
  const {layout,data,options,setRefs}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();
  // Access globally  refs
  useEffect(() => {
   setRefs({...layout.refs,cover:createRef()})
  }, []);

    return ( 
<>


<HeroVideoCover ref={layout.refs.cover}/>
 

 


<div className="container px-2 py-5" >


 
  {/* <div className="container px-2 py-5" style={width:'fit-content'}> */}
  <div className="text-center">
    {/* <div className="masthead-followup-icon d-inline-block mb-2 text-white bg-white"> */}
    <div className="col-12 col-sm-12 col-md-10 col-lg-8 d-inline-block mb-2 text-white bg-white d-inline-block mb-2 text-white bg-white">
   
    {/* <img src={iconSq} alt="aaa" style={{width:64,height:64}}/> */}
    {/* <img src={iconRc} alt="aaa" style={{width:'auto',height:64}}/> */}
    <img src={imgHeader} alt={options.companyData.name} />
    
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" focusable="false" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 16a6 6 0 006-6c0-1.655-1.122-2.904-2.432-4.362C10.254 4.176 8.75 2.503 8 0c0 0-6 5.686-6 10a6 6 0 006 6zM6.646 4.646c-.376.377-1.272 1.489-2.093 3.13l.894.448c.78-1.559 1.616-2.58 1.907-2.87l-.708-.708z" clipRule="evenodd"></path>
  </svg> */}
  
    </div>
      <h2 className="display-6 fw-normal">
        {/* Go further with Bootstrap Themes */}
        {L("goFurtherWithFieldExperts")}
        </h2>
    <p className="col-md-10 col-lg-8 mx-auto lead">
      {/* Need something more than these examples? Take Bootstrap to the next level with premium themes from the <a href="https://themes.getbootstrap.com/">official Bootstrap Themes marketplace</a>. They’re built as their own extended frameworks, rich with new components and plugins, documentation, and powerful build tools. */}
      
      {L("needSomethingMore")}
  
    </p>
    {/* <a href="https://themes.getbootstrap.com/" className="btn btn-lg btn-outline-primary mb-3">Browse themes</a> */}
  </div>
  
  {/* <img className="d-block img-fluid mt-3 mx-auto" srcSet="https://getbootstrap.com/docs/5.0/assets/img/bootstrap-themes-collage.png,
                                                      https://getbootstrap.com/docs/5.0/assets/img/bootstrap-themes-collage@2x.png 2x" src="https://getbootstrap.com/docs/5.0/assets/img/bootstrap-themes-collage.png" alt="Bootstrap Themes" width="1150" height="320" loading="lazy"/>
   */}
  {/* <img className="d-block img-fluid mt-3 mx-auto"   src={require('./../assets/img/collage.png')} alt={L('brandName')} width="1150" height="320" loading="lazy"/> */}
   
  
  </div>
 
  <div className="container " >

  <div className="row " >

<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
<img src={require('./../assets/img/brands/evergreen-logo-1.svg').default} className="img-fluid" alt=""/>
{/* <img src="https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-1.png" className="img-fluid" alt=""/> */}
</div>

<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
  <img src={require('./../assets/img/brands/warimo-logo.svg').default} className="img-fluid" alt=""/>
</div>

<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
  <img src={require('./../assets/img/brands/lifecrops-logo.svg').default} className="img-fluid" alt=""/>
</div>

<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
  <img  src={require('./../assets/img/brands/shopee-logo.svg').default} className="img-fluid" alt=""/>
</div>

<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
  <img src={require('./../assets/img/brands/citrus-logo.png')} className="img-fluid" alt=""/>
</div>

<div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
  <img src={require('./../assets/img/brands/trustly-logo.png')} className="img-fluid" alt=""/>
</div>

</div>

</div>

  <div className="container  px-4 ">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
         
          <div className="col-md-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{L('ResponsiveCereal')}</h1>
            <p className="lead">{L('ResponsiveCerealShort')}</p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              {/* <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button> */}
              {/* <a href="about">
              <button type="button" className="btn btn-outline-secondary btn-lg px-4">Know more...</button>
              </a> */}
            </div>
          </div> 
                    {/* <div className=" d-none d-sm-block d-block col-sm-12 col-lg-6"> */}

          <div className="col col-md-6 ">

          
          <img src={imgOperations} alt="Operations" className="d-block mx-lg-auto img-fluid" loading="lazy"/>
            {/* <img src="https://getbootstrap.com/docs/5.3/examples/heroes/bootstrap-themes.png" /> */}
          </div>   
        </div>
  </div>

  <div className="container px-4">
  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
      <div className="col d-flex align-items-start">
          <FontAwesomeIcon icon={faWheatAwn}  style={{width:"2em",height:"2em"}} className="bi text-muted flex-shrink-0 me-3" />
          <div>
          <h5 className="fw-bold mb-0">{L('GrainExperts')}</h5>
          <p>{L('GrainExpertsShort')}</p>
        </div>
      </div>
      <div className="col d-flex align-items-start">
          <FontAwesomeIcon icon={faShip}  style={{width:"2em",height:"2em"}} className="bi text-muted flex-shrink-0 me-3" />
          <div>
          <h5 className="fw-bold mb-0">{L('IMEXOperations')}</h5>
          <p>{L('IMEXOperationsShort')}</p>
        </div>
      </div>
      <div className="col d-flex align-items-start">
          <FontAwesomeIcon icon={faPeopleCarryBox}  style={{width:"2em",height:"2em"}} className="bi text-muted flex-shrink-0 me-3" />
          <div>
          <h5 className="fw-bold mb-0">{L('DistributionNetwork')}</h5>
          <p>{L('DistributionNetworkShort')}</p>
        </div>
      </div>
      <div className="col d-flex align-items-start">

 
      <FontAwesomeIcon icon={faBoxOpen}  style={{width:"2em",height:"2em"}} className="bi text-muted flex-shrink-0 me-3" />
          <div>
          <h5 className="fw-bold mb-0">{L('ExclusiveProducts')}</h5>
          <p>{L('ExclusiveProductsShort')}</p>
        </div>
      </div>
     
    </div>
  </div>

  <div className="container  px-4 ">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
         
       
                    {/* <div className=" d-none d-sm-block d-block col-sm-12 col-lg-6"> */}

          <div className="col col-md-6 p-8"
          style={{backgroundImage:`url('${require("../assets/img/textures/manga3.svg").default}')`,
          backgroundSize: 'cover',  
          backgroundPosition: 'center'
            }} > 
          <img style={{padding:'10%'}} src={require('../assets/img/assorti-r.png')} alt="Operations" className="d-block mx-lg-auto img-fluid" loading="lazy"/>
            {/* <img src="https://getbootstrap.com/docs/5.3/examples/heroes/bootstrap-themes.png" /> */}
          </div>   
          <div className="col-md-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{L('ExclusiveProductsLine')}</h1>
            <p className="lead">{L('ExclusiveProductsLineShort')}</p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <a href='products'>
              <button type="button" className="btn btn-dark btn-lg px-4 me-md-2">See Products</button>
              </a>
              {/* <a href="about">
              <button type="button" className="btn btn-outline-secondary btn-lg px-4">Know more...</button>
              </a> */}
            </div>
          </div> 
        </div>
  </div> 

 



{/* 
  <div className="bg-dark text-secondary px-4 text-center">
        <div className="py-5">
          <h1 className="display-5 fw-bold text-white"> {L("CostumizedBranding")}</h1>
          <div className="col-lg-6 mx-auto">
            <p className="fs-5 mb-4">{L('Delveinto')}</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button type="button" className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold">Custom button</button>
              <button type="button" className="btn btn-outline-light btn-lg px-4">Secondary</button>
            </div>
          </div>
        </div>
  </div> 
  <div className="container ">
    <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
      <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
        <h1 className="display-4 fw-bold lh-1 text-body-emphasis">{L('topStandards')}</h1>
        <p className="lead">{L('highestComply')}</p>
        <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
          <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Primary</button>
          <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
        </div>
      </div>
      <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
  
      <img className="rounded-lg-3" src="https://getbootstrap.com/docs/5.3/examples/heroes/bootstrap-docs.png" alt="" width="720"/>
       </div>
    </div>
  </div> */}

  

</>
    );
});
export default Home;
