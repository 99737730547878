import React, {  forwardRef } from 'react'; 
import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';  
  
 
import imgHeader from '../assets/logo/full-gb.svg';
 
const Home = forwardRef((props, ref) => {

  // Namespace 
  const {layout,data,options}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();
  // Access globally  refs

    return (  

<div style={{   width: '100%', maxWidth: 330, padding: 15, margin: 'auto' }} >
  <form>
    {/* <img class="mb-4" src="/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
    <img class="mb-4"  style={{height:'auto',width:'-webkit-fill-available'}} src={imgHeader} alt={options.companyData.name} />
    {/* <h1 class="h3 mb-3 fw-normal">Please sign in</h1> */}

    <div class="form-floating">
      <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
      <label for="floatingInput">{L('Emailaddress')}</label>
    </div>
    <div class="form-floating">
      <input type="password" class="form-control" id="floatingPassword" placeholder="Password"/>
      <label for="floatingPassword">{L('Password')}</label>
    </div>

    <div class="checkbox mb-3">
      <label>
        <input type="checkbox" value="remember-me"/> {L('Rememberme')}
      </label>
    </div>
    <button class="w-100 btn btn-lg btn-dark" type="submit">{L('Submit')}</button>
    {/* <p class="mt-5 mb-3 text-muted">© 2017–2021</p> */}
  </form>
</div>
 

 
    );
});
export default Home;
