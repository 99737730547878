import React, {  forwardRef } from 'react'; 
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';  
import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive,faFile,faFileAlt,faIdBadge,faFileMedical} from '@fortawesome/free-solid-svg-icons'; 

 
 
 const Component = forwardRef((props, ref) => {
  const {options}= useNamespace();
  const {L} = useLocale();
    return ( 
     <>
     <div className="mb-1  ">
          <h3 className="fst-bold">{options.companyData.name} Doc Base</h3>
          <small className="mb-0">{L('StayTuned')}</small>
        </div>
 
      <div className="position-sticky" style={{top: '2rem'}}>
      
        <div>
         
          <ul className="list-unstyled">
            <li>
              <a href="docs?src:cits" className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top" >
              <FontAwesomeIcon style={{height:48,width:48}} icon={faFile} />
                {/* <svg className="bd-placeholder-img" width="100%" height="96" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"></rect></svg> */}
                <div className="col-lg-8">
                  <h6 className="mb-0">Compliance with International Trade Standards</h6>
                  <small className="text-body-secondary">en 1.43.2</small>
                </div>
              </a>
            </li>

            <li>
            <a href="docs?src:cms" className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top" >
              <FontAwesomeIcon style={{height:48,width:48}} icon={faFileAlt} />
                {/* <svg className="bd-placeholder-img" width="100%" height="96" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"></rect></svg> */}
                <div className="col-lg-8">
                  <h6 className="mb-0">Corporate Mission statement</h6>
                  <small className="text-body-secondary">en 0.0.21</small>
                </div>
              </a>
            </li>
          
            <li>
            <a href="docs?src:tgecp"  className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top" >
              <FontAwesomeIcon style={{height:48,width:48}} icon={faFileMedical} />
                {/* <svg className="bd-placeholder-img" width="100%" height="96" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"></rect></svg> */}
                <div className="col-lg-8">
                  <h6 className="mb-0">Environmental Conservation Policy</h6>
                  <small className="text-body-secondary">en 0.0.2 </small>
                </div>
              </a>
            </li>
           
           
           
            
          </ul>
        </div> 
{/* 
        <div className="p-4">
          <h4 className="fst-italic">Archives</h4>
          <ol className="list-unstyled mb-0">
            <li><a href="#">March 2021</a></li>
            <li><a href="#">February 2021</a></li>
            <li><a href="#">January 2021</a></li>
            <li><a href="#">December 2020</a></li>
            <li><a href="#">November 2020</a></li>
            <li><a href="#">October 2020</a></li>
            <li><a href="#">September 2020</a></li>
            <li><a href="#">August 2020</a></li>
            <li><a href="#">July 2020</a></li>
            <li><a href="#">June 2020</a></li>
            <li><a href="#">May 2020</a></li>
            <li><a href="#">April 2020</a></li>
          </ol>
        </div>

        <div className="p-4">
          <h4 className="fst-italic">Elsewhere</h4>
          <ol className="list-unstyled">
            <li><a href="#">GitHub</a></li>
            <li><a href="#">Twitter</a></li>
            <li><a href="#">Facebook</a></li>
          </ol>
        </div> */}
      </div>
     </>
    );
});
export default Component;
 
 
