
import React from 'react';
import Layout from '../../components/Layout';
import News from '../../components/News.js' 
const App = () => { 
  return (     <Layout>   <News/>    </Layout>    );
}
 

  export default App;
 
 