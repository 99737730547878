import React, {  useEffect, forwardRef, useState } from 'react';

import useLocale from '@iorp/react-aid/lib/components/Locale/hooks/useLocale';   
import useNamespace from '@iorp/react-aid/lib/components/Namespace/hooks/useNamespace';
   
import Accordion from 'react-bootstrap/Accordion';
  
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import './style.css'
// useing flags
require('@iorp/react-aid/lib/components/Locale/assets/flags/css/flag-icons.css');


 
 

const ProductGalleryItem=({product,...props})=>{
 
  // Namespace 
  const {layout,data,getParams,options}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();

  return (
  <div  className="col mb-3">
  <div  className="card shadow-sm" > 

<a href={`products?pid:${product.id}`}>
 <div 
  style={{backgroundImage:`url('${require("../../assets/img/textures/manga2.svg").default}')` , backgroundSize: 'cover',  backgroundPosition: 'center'  }}>
    <img src={require(`../../assets/products/media/${product.id}r.webp`)} className="aid-img bd-placeholder-img card-img-top" />
 </div>
</a>
    <div  className="card-body" style={{backgroundColor:'white'}}>
    <div className="card-title h5">{product.title}</div>
    <p  className="card-text">{product.textShort}</p>

     
    {/* <div className="d-flex justify-content-between align-items-center"> */}
    <div className="  justify-content-between align-items-center">
        <span className="aid-label btn-sm">{product.weight}{L('gr')}</span>
        <span className="aid-label  btn-sm">{product.packMaterial}</span>
        <span className="aid-label  btn-sm">{product.packsPerBox}ppb</span>
        <span className="aid-label  btn-sm"><i className={`fi fi-${product.origin}` } alt={L(`${product.origin}CountryName`)}></i></span>
        <span className="btn btn-sm text-body-secondary"><a style={{textDecoration:'none'}}href={`products?pid:${product.id}`}>more</a></span>
     
        </div>
     
      
    
    </div>
  </div>
</div>
)
}

function mergeProductsDataAndStrings(obj1, obj2) {
  const result = [];
  
  for (const key in obj1) {
    if (obj2[key]) {
      result.push({ id: key, ...obj1[key], ...obj2[key] });
    }
  }
  
  return result;
}

const ProductGallery = () => {
   
  // Namespace 
  const {layout,data,getParams,options}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();


const products = mergeProductsDataAndStrings( require ('../../assets/products/data/products.json'),require (`../../assets/products/strings/${locale.current}.products.json`));
  

  // const products = [
  //   { id: 1, title: 'Product 1', imageUrl: 'https://example.com/image1.jpg', shelfLifeDays: 30 },
  //   { id: 2, title: 'Product 2', imageUrl: 'https://example.com/image2.jpg', shelfLifeDays: 30 },
  //   // Add more products as needed
  // ];

  return (
 <>
    <div className="mb-3 pb-1 border-bottom">
          <h3 className="fst-bold ">{options.companyData.name} {L('ExclusiveProducts')}</h3>
          <small className="text-muted"> {L('ExclusiveProductsInRegion')}</small> 
        </div>
   
 {/* <h1 className="pb-4 mt-4  border-bottom">True Grains™ <small>Exclusive Products</small></h1>
 <small className="text-muted">Here are True Grains™ Exclusive Products available on your region.</small> */}
 <div  className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">  
   {products.map(product => (
     <div key={product.id} className="col">
      
      <ProductGalleryItem product={product}/>
    
     </div>
   ))}
 </div>
</>
  );
};
 
// import './ProductDetails.css'; // Import your CSS file for styling

 

  
const ProductDetails = ({pid,...props}) => {
   
  // Namespace 
  const {layout,data,getParams,options}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();
   const productData = require ('../../assets/products/data/products.json')[pid];
  const productStrings = require (`../../assets/products/strings/${locale.current}.products.json`)[pid];
   const product = (productData)?{id:pid,...productData,...productStrings}:null;
   
  const [key, setKey] = useState('description');
   
 


  return(
  <>
  {product &&    
  <div className="product-details">
    <div  className="row mt-4 border-bottom ">
    <h1 className="">True Grains™ <small>{product.title}</small></h1>
   <small className="text-muted">{product.textShort}</small>
    </div>

      <div className="row pt-4">
        <div className="col-12 col-md-6">
        {/* <img className="product-image" src={require(`./../assets/products/media/${product.id}f.webp`)} alt="Product Image" /> */}

          {/* <img className="product-image" src="https://via.placeholder.com/300" alt="Product Image" /> */}
          <div  style={{   backgroundImage:`url('${require("../../assets/img/textures/manga2.svg").default}')` , backgroundSize: 'cover',  backgroundPosition: 'center'  }}>
          <img src={require(`../../assets/products/media/${product.id}f.webp`)} className="aid-img bd-placeholder-img card-img-top" />
          </div>
        </div>
        <div className="col-12 col-md-6">


          <ul className="list-group">
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Product name</h6>
              <small className="text-muted">{product.title}</small>
            </div>
            {/* <span className="text-muted">$12</span> */}
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Code</h6>
              <small className="text-muted">{product.barcode}</small>
            </div> 
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Origin</h6>
              <small className="text-muted">{product.origin}</small>
            </div> 
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Shelf Life</h6>
              <small className="text-muted">{product.shelfLife}</small>
            </div> 
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Weight</h6>
              <small className="text-muted">{product.weight}{L('gr')}</small>
            </div> 
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Package Material</h6>
              <small className="text-muted">{product.packMaterial}</small>
            </div> 
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Package dimensions</h6>
              <small className="text-muted">{product.width}{L('mm')} * {product.height}{L('mm')}</small>
            </div> 
          </li>
          <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Packages Per Box</h6>
              <small className="text-muted">{product.packsPerBox}</small>
            </div> 
          </li>
          {/* <li className="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 className="my-0">Available regions</h6>
             
              <small className="text-muted"> {product.regions.map((item, index) => (
         
                <i key={index} className={`fi fi-${item} me-2` } alt={L(`${item}CountryName`)}></i> 
              ))}</small>
            </div> 
          </li> */}
          {/* <Accordion defaultActiveKey="0" className='' > */}
          <Accordion className='' >
      <Accordion.Item eventKey="0"  className='' >
        <Accordion.Header  className=''  >
            <div>
              <h6 className="mb-1">Available in regions</h6>
              <span  >
                 {product.regions.map((item, index) => (
         
         <i key={index} className={`fi fi-${item} me-2` } alt={L(`${item}CountryName`)}></i> 
       ))}</span>        
           </div>
            {/* <span className="text-muted">$12</span>  */}
            </Accordion.Header>
        <Accordion.Body>
          <ul className={'list-group'}>
        {product.regions.map((item, index) => (
         <li className={'list-group-item'} key={index}>    
              <i className={`fi fi-${item} me-2` } alt={L(`${item}CountryName`)}></i> 
              {L(`${item}CountryName`)}
         </li>
       ))}
       </ul>
        </Accordion.Body>
      </Accordion.Item> 
        </Accordion>   
   
          </ul>
       

        
        </div>
      </div>

      <div className='row mt-4' >
    <Tabs
      id="controlled-tab"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3" 
    >
      <Tab eventKey="description" title="Description">
        <div style={{minHeight:180}}>
        {product.textLong}

          </div>
      </Tab>
      <Tab eventKey="documents" title="Documents">
      <div style={{minHeight:180}}>
      <ul className={''}>
        {product.documents.map((item, index) => (
         <li className={''} key={index}>    
              <span>{L(`${item}`)}</span>  
         </li>
       ))}
       </ul>

          </div>
      </Tab>
      <Tab eventKey="features" title="Features" >
      <div style={{minHeight:180}}>
      <ul className={''}>
        {product.features.map((item, index) => (
         <li className={''} key={index}>    
              <span>{L(`${item}`)}</span>  
         </li>
       ))}
       </ul>
          </div>
      </Tab>
    </Tabs> 

      </div>
    </div>}
  {!product && <p>This product is not available in your region anymore,see the <a href='products'>updated product list</a>.</p>}
  </>
  )
};

const ProductViewer = () => {
   
  // Namespace 
  const {layout,data,getParams,options}= useNamespace();
  // Language
  const {locale,L,setLanguage,setStrings} = useLocale();
  // const products = mergeProducts( require ('../../assets/products/data/products.json'),require (`../../assets/products/strings/${locale.current}.products.json`));
  const [pid,setPid]=useState(null)
  useEffect(() => {
    setPid(getParams().pid);
  }, []);
  return(
 
   <div className="bg-body-tertiary pt-1">  
<div className="container"> 
 
 { pid && <ProductDetails pid={pid}/>}
 { !pid && <ProductGallery />}
 
 </div>
</div> 
 
  )
};
export default ProductViewer;
 
 