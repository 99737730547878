 
module.exports ={
  
    namespaceOptions:{ 

    companyData:{
      address:"938 Bạch Đẳng, Hau Bà Trưng, Hà Nội",
       addressMaps:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4443.675337190841!2d105.86859203035725!3d21.004706784826165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135adc2f032c48f%3A0xa11d6bb94688820!2zOTM4IMSQLiBC4bqhY2ggxJDhurFuZywgVGhhbmggTMawxqFuZywgSGFpIELDoCBUcsawbmcsIEjDoCBO4buZaSAxMDAwMCwgVmlldG5hbQ!5e0!3m2!1sen!2sus!4v1713784277401!5m2!1sen!2sus",

      name:"True Grains™",
      nameLong:"The True Grains Company", 
      nameShort:"True Grains",
      nameFormal:"True Grains Ltd.",
      supportPhone:'+79936273600',
      supportMail:'support@truegrains.vn',
      salesPhone:'+84862453600',
      salesMail:'sales@truegrains.vn',
      exportsPhone:'+79936273600',
      exportsMail:'exports@truegrains.vn',
     
      
    },
    },
    localeOptions : {    
        default:'vn',
        available:['en','vn','ru']
      
      }
};