  
 import React from 'react';
 import Layout from '../../components/Layout';
import Contact from '../../components/Contact' 
 const App = () => { 
   return (     <Layout>   <Contact/>    </Layout>    );
 }
  
 
   export default App;
  