
import React, { useState, useEffect } from 'react';

 import Layout from '../../components/Layout';
  


import httpRequest from  '@iorp/node-aid/src/network/httpRequest';
  
 

const ApiTest = () => {
  const [apiResponse, setApiResponse] = useState('');
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [jsonInput, setJsonInput] = useState('');
  const [profileOptions] = useState([
    { name: 'Profile 1', data: { a: 1, b: 2, c: 3 }, api: process.env.REACT_APP_API_URL +'/test' },
    { name: 'Profile 2', data: { x: 10, y: 20, z: 30 }, api:  process.env.REACT_APP_API_URL +'/test' },
    // Add more profiles as needed
  ]);

  useEffect(() => {
    // Select 'Profile 1' when the component mounts
    const initialProfile = profileOptions.find(profile => profile.name === 'Profile 1');
    setSelectedProfile(initialProfile);
    setJsonInput(JSON.stringify(initialProfile?.data, null, 2));
  }, [profileOptions]);

 
  const handleButtonClick = async () => {
    // Example usage
   

  // Using httprequest from node=aid
  httpRequest( selectedProfile.api,JSON.parse(jsonInput),'POST')
  .then(response => {
    console.log('API Response:', response);
    setApiResponse(response);
  })
  .catch(error => {
    console.error('Error:', error);
    setApiResponse({error:true,exception:error}); 
    // Handle errors
  });

  
  

  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    const selectedProfile = profileOptions.find(profile => profile.name === selectedValue);
    setSelectedProfile(selectedProfile);
    setJsonInput(JSON.stringify(selectedProfile?.data, null, 2));
  };

  return (
    <div>
      <h1>API Data Fetcher</h1>
      <div>
        <label>Select Profile:</label>
        <select value={selectedProfile ? selectedProfile.name : ''} onChange={handleDropdownChange}>
          {profileOptions.map((profile, index) => (
            <option key={index} value={profile.name}>{profile.name}</option>
          ))}
        </select>
      </div>
      {selectedProfile && selectedProfile.api && (
        <div>
          <label>Selected API:</label>
          <input type="text" value={selectedProfile.api} readOnly />
        </div>
      )}
      <div>
        <label>Input JSON:</label>
        <textarea value={jsonInput} onChange={(e) => setJsonInput(e.target.value)} rows={4} />
      </div>
      <div>
        <button onClick={handleButtonClick}>Fetch Data</button>
      </div>
      <div>
        <label>Output:</label>
        <textarea value={JSON.stringify(apiResponse,null,2)} readOnly rows={4} />
      </div>
    </div>
  );
};
 
 const App = () => { 
   return (     <Layout>   <ApiTest/>    </Layout>    );
 }
  
 
   export default App;
  
  