  
 import React from 'react';
 import Layout from '../../components/Layout';
import ProductViewer from '../../components/ProductViewer' 
 const App = () => { 
   return (     <Layout>   <ProductViewer/>    </Layout>    );
 }
  
 
   export default App;
  
  

 