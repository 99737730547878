
 import React from 'react';
 import Layout from '../../components/Layout';
import Landing from '../../components/Landing' 
 const App = () => { 
   return (     <Layout>   <Landing/>    </Layout>    );
 }
  
 
   export default App;
  
  